import { create } from 'zustand';

interface ReaderSettings {
  lineSpacing: number | undefined;
  setLineSpacing: (spacing: number | undefined) => void;
  fontSize: number | undefined;
  setFontSize: (size: number | undefined) => void;
}

export const useReaderStore = create<ReaderSettings>((set, get) => ({
  lineSpacing: undefined,
  setLineSpacing: (spacing: number | undefined) => {
    const currentSpacing = get().lineSpacing;
    if (currentSpacing !== spacing) {
      set({ lineSpacing: spacing });
    }
  },
  fontSize: undefined,
  setFontSize: (size: number | undefined) => {
    const currentSize = get().fontSize;
    if (currentSize !== size) {
      set({ fontSize: size });
    }
  },
}));
